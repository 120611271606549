<template>
   <div class="haus">
    <div v-for="list in lists" :key="list" class="drop-zone">
      <h3>{{ list.bez }}</h3>
      <draggable
        class="list-group"
        :list="list.names"
        group="people"
        @end="save"
        itemKey="name"
      >
        <div v-for="item in list.names" :key="item.id">
          <div class="list-group-item">{{ item.name }}</div>
        </div>
      </draggable>
    </div>
  </div>
</template>


<script>
import { VueDraggableNext } from "vue-draggable-next"
import axios from "axios"
//import jason from '../public/data.json'

export default {
  
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable: VueDraggableNext,
  },
  data() {
    return {
      lists: [], //jason
      
    };
  },
  

  methods: {
    save() {
      axios
        .post("./test.php", {
          arr: this.lists,
        })
        .then((result) => {
          this.liste = result.data;
          //console.log(result.data)
        });
    },
  },
  mounted() {
    
    axios.get("./test.php").then((result) => {
      this.lists = result.data;  
      setInterval(() => {
         axios.get("./test.php").then((result) => {
          this.lists = result.data
          //console.log(this.lists)     
          
      })
      },10000)
    })
    
  }
  
};
</script>

<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}
h3 {
  margin-top: 1px;
  color:#fff;
  font-weight:normal;
}
.haus {
  display: flex;
  max-width:700px;
  flex-flow:row wrap;
  justify-content: space-around;
  margin:0px auto;
}
.drop-zone {
  background-color: cadetblue;
  margin: 10px auto;
  padding: 10px;
  width: 150px;
}
.list-group {
  padding: 5px ;
  min-height:20px;
  border: 1px solid #fff;
}

.list-group-item {
  background-color:ghostwhite;
  padding: 10px 0;
  margin-bottom: 10px;
}
.list-group > div:nth-last-of-type(1) .list-group-item {
  margin-bottom: 0px;
}
</style>
